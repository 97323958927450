import React from 'react';
import {graphql, Link} from 'gatsby';
import StaticLayout from 'components/layouts/static-layout';
import SEO from 'components/seo';
import PropTypes from 'prop-types';
import {ColorBox, Container, P, Wave} from 'components/wrappers';
import {HEADER_BG_COLOR, HERO_BG_COLOR, HERO_COLOR} from 'assets/theme';

const NotFoundPage = ({data, location}) => {
  const {title} = data.site.siteMetadata;

  return (
    <StaticLayout
      {...{
        bgColor: HERO_BG_COLOR,
        location,
        seo: {title: '404: Not Found'},
      }}
      title={title}
    >
      <SEO title="404: Not Found" />
      <ColorBox bgColor={HERO_BG_COLOR} color={HERO_COLOR}>
        <Container hero>
          <header>
            <Link to={'/'}>Back to Home</Link>
          </header>
          <h1>Page Not Found</h1>
          <P l>These aren&#39;t the droids you are looking for...</P>
        </Container>
      </ColorBox>
      <Wave bgColor={HERO_BG_COLOR} />
      <ColorBox bgColor={HEADER_BG_COLOR} color={HERO_COLOR}>
        <Container heroPadding></Container>
      </ColorBox>
      <Wave bgColor={HERO_BG_COLOR} flip />
      <br />
    </StaticLayout>
  );
};

NotFoundPage.propTypes = {
  data: PropTypes.shape({
    site: PropTypes.object,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

export default NotFoundPage;

export const GATSBY_QUERY = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
